import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import RowWrapper from '../utils/rowWrapper';
import Logo from "../components/logo"
import styled, { css } from 'styled-components';


const Navbar = styled.div`
  position: absolute;
  width: 100%;

  .row {
    display: flex;
    align-items: center;
    padding: 4rem 0 0 0;

    .logo-black {
      display: none;
    }

    .logo {
      z-index: 500;
    }

    nav {
      margin-left: auto;
  
      a {
        padding: 8px 0;
        margin-left: 30px;
      }
    }
  }
  

  nav {
    a {
      text-transform: uppercase;
      color: #fff;
      cursor: pointer;
      transition: all 0.2s;
  
      &:hover, &:active, &.active {
        color: ${props => props.theme.colors.primary};
      }
    }
  }

  .mobile-nav {
    display: none;
    font-size: 35px;
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    margin-left: auto;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.1);
    }
  }

  @media ${props => props.theme.breakpoints.down('md')} {
    nav {
      display: none;
    }

    .mobile-nav {
      display: block;
    }

    .logo {
      height: 80px !important;
    }

    .row {
      padding: 4rem 3rem;
    }
  }
`;

const ModalNav = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.dark};
  z-index: -1;
  display: none;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  opacity: 0;

  ${props => 
    props.mobileOpen && 
    css`
      display: flex;
      opacity: 1;
      z-index: 10000;
    `}

  nav {
    display: flex;
    flex-direction: column;

    a {
      color: #fff;
      font-size: 130%;
      padding: 15% 5%;

      &:hover, &:active, &.active {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`

const NavContainer = styled.div`
  width: 100%;

${props => 
  props.sticky && 
  css`
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px #efefef;
    box-shadow: 0 2px 2px #efefef;
    z-index: 999;
    padding: 10px 0;

  .row {
    padding-top: 0;

    .logo {
      display: none;
    }

    .logo-black {
      height: 50px !important;
      display: block;
    }

    .mobile-nav {
      color: ${props => props.theme.colors.primary};
    }

    nav {
      a {
        color: ${props => props.theme.colors.dark};
        font-size: 1.6rem;

        &:hover, &:active, &.active {
        color: ${props => props.theme.colors.primary};
      }
      }
    }
  }
  `}


`

let prevScroll = 0;

export default function () {
  const [sticky, setSticky] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleMobileOpen = () =>
    setMobileOpen(!mobileOpen);

  useEffect(() => {
    const listener = () => {
      const currScroll = window.scrollY;
      const scrollingDown = currScroll > prevScroll;
      prevScroll = window.scrollY;

      if (scrollingDown && currScroll > 50)
        setSticky(true)
      else
        setSticky(false)
    }

    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  const Nav = () =>
  <nav>
    <Link to="/" activeClassName="active">Home</Link>
    {/* <Link to="/collections">Collections</Link> */}
    <Link to="/gallery" activeClassName="active">Gallery</Link>
    <Link to="/about" activeClassName="active">About</Link>
    <Link to="/#contact" activeClassName="active">Contact Us</Link>
  </nav>

  // console.log(mobileOpen, sticky);

  return (
    <Navbar>
      <ModalNav mobileOpen={mobileOpen}>
        <Nav />
      </ModalNav>
      <NavContainer sticky={sticky}>
        <RowWrapper className="row">
          <Logo className="logo" id="logo" black={false} />
          <Logo className="logo-black" />
          <Nav />
          {!mobileOpen ? 
            <MenuIcon className="mobile-nav" onClick={handleMobileOpen} /> 
            : <CloseIcon style={{zIndex: 11000, display: ''}} className="mobile-nav" onClick={handleMobileOpen} />
          }
        </RowWrapper>
      </NavContainer>
    </Navbar>
  )
}