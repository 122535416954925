import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import RowWrapper from '../utils/rowWrapper';

import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook';

import gsap from 'gsap';

const Footer = styled.footer`
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  min-height: 25rem;
  position: relative;
  padding: 5rem;
  

  .attribution {
    margin-top: 3rem;

    a {
      text-decoration: none;
      color: ${props => props.theme.colors.body};
      font-size: 1.3rem;
    }
  }

  @media ${props => props.theme.breakpoints.down('sm')} {
    padding: 3rem 0;
    height: auto;
  }
`

const FooterRow = styled(RowWrapper)`
  max-width: 90%;
  text-align: center;

  @media ${props => props.theme.breakpoints.down('sm')} {
   max-width: 100%;
  }
`

const Address = styled.address`
  color: ${props => props.theme.colors.body};
  font-size: 1.3rem;
  line-height: 1.5;

  a {
    color: ${props => props.theme.colors.body};
    font: inherit;
  }
`

const Links = styled.div`

h6 {
  font-size: 1.4rem;
  color: ${props => props.theme.colors.body};
  margin-bottom: 1rem;
}

.social-icons {
  color: ${props => props.theme.colors.primary};
  font-size: 2.5rem;
  margin-right: 1rem;
}
`

const Back = styled.div`
  width: 100%;
  margin-top: 6rem;
  text-align: center;
  color: ${props => props.theme.colors.body};
  cursor: pointer;
  /* border: 1px solid ${props => props.theme.colors.body};
  padding: 1rem;
  border-radius: 5px; */

  span {
    font-size: 1.4rem;
    border-bottom: 1px solid ${props => props.theme.colors.body};
    padding: .5rem;
  }
`

const FooterHeader = styled.h6`
   font-size: 1.5rem;
   margin-bottom: .5rem;
   color: ${props => props.theme.colors.body};
`

const Hours = styled.ul`
  margin-top: 1rem;
  color: ${props => props.theme.colors.body};
   
   li {
     font-size: 1.4rem;
     margin: 1.5rem 0;
     line-height: 1.6rem;

     .day {
      letter-spacing: 1px;
      font-size: 1.5rem;
     }

     .parenth {
       font-size: 1.2rem;
       letter-spacing: 1px;
     }
   }
`
 
export default function (props) { 

  const click = () => {
    gsap.to(window, {duration: 2, scrollTo: 0});
  }

  return (
 <Footer>
   <FooterRow centered={true}>
   <Grid container justify="center" spacing={4}>
     <Grid item md={4} xs={12}>
      <FooterHeader>Store Hours</FooterHeader>
      <Hours>
        <li><span className="day">Sunday - Thursday</span> ... 12:00 PM - 6:00 PM</li>
        <li><span className="day">*Wednesday</span> ... 12:00 PM - 8:00 PM <br /><span className="parenth">(*Williamsburg Showroom only)</span></li>
      </Hours>
     </Grid>
     <Grid item md={4} xs={12}>
       <Address>
        <FooterHeader>DW &nbsp; Warehouse</FooterHeader>
        62-84 Industrial Pl <br />
        Middletown, NY 10940 <br />
        <a href="tel:845-343-3366">(845) 343-3366</a>
       </Address>
     </Grid>
     <Grid item md={4} xs={12}>
      <Links>
      <h6>Follow Us</h6>
      <a href="https://www.instagram.com/dw_furniture/"><InstagramIcon className="social-icons" /></a>
      <a href="https://www.facebook.com/dwdesign.ny/"><FacebookIcon className="social-icons" /></a>
      </Links>
      <p className="attribution"><a href="https://novosoftcode.com/">Made by Novosoft Sofware Solutions</a></p>
     </Grid>
    
   </Grid>
   <Back onClick={click}>
   <span>&uarr; Top</span>
   </Back>
   </FooterRow>
 </Footer>
)
};
 