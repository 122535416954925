/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { ThemeProvider } from "styled-components"
import { theme } from "../utils/theme"

// import Header from "./header"
import Navbar from "./navbar";
// import Header from "./header"
import Footer from './footer'

import favicon from '../images/favicon/favicon.ico';
import appleFav from '../images/favicon/apple-touch-icon.png';
 import fav32 from '../images/favicon/favicon-32x32.png';
 import fav16 from '../images/favicon/favicon-16x16.png';

import "../css/main.scss"
import "../css/tween.scss"

// import startSlide from '../utils/tween';


const Layout = ({ children, jsonld }) => {

  // useEffect(() => { // all gsap animations are declared here

  //   startSlide();

  // }, []);




  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  
  const schema = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    "name": "D&W Design Furniture",
    "alternateName": "dw design",
    "url": data.site.siteMetadata.siteUrl,
    "description": data.site.siteMetadata.description,
    "logo": "https://d-wdesign.com/static/logo-4d8d3da1811ecc735168581dd1114e25.png",
    "sameAs": [
      "https://www.instagram.com/dw_furniture/",
      "https://www.facebook.com/dwdesign.ny/"
      ]
    }

    console.log(jsonld);

  return (
    <>
    <ThemeProvider theme={theme}>
      <div>
      <Helmet htmlAttributes={{
        lang: 'en',
      }}
      title={data.site.siteMetadata.title}
      >

        <meta property="og:title" content={data.site.siteMetadata.title} />

        <link rel="icon" href={favicon} />
        <link rel="apple-touch-icon" sizes="180x180" href={appleFav} />
        <link rel="icon" type="image/png" sizes="32x32" href={fav32} />
        <link rel="icon" type="image/png" sizes="16x16" href={fav16}/>
        
        <meta property="og:description" content={data.site.siteMetadata.description} />
        <meta name="description" content={data.site.siteMetadata.description} />

        <title>{data.site.siteMetadata.title}</title>
        
        {schema && <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>}

        {jsonld && jsonld.map(cur => <script type="application/ld+json">
          {JSON.stringify(cur)}
        </script>)}
      </Helmet>
        <Navbar />
        <main className="main-sections">{children}</main>
        <Footer />
      </div>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
