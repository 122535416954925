const size = {
  xs: '450px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
}

export const theme = {
  colors: {
    primary: '#bdae66',
    primaryDark: '#bdae66',
    body: '#f6f2f2',
    dark: '#2C2C2C',
  },
  breakpoints: {
    up: bp => `(min-width: ${size[bp]})`,
    down: bp => `(max-width: ${size[bp]})`,
    bewteen: (bp1, bp2) => `(min-width: ${size[bp1]}) and (max-width: ${size[bp2]})`,
    only: bp => `(width: ${size[bp]})`
  },
  maxWidth: '1280px',
  maxWidthMd: '960px'
}