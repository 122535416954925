import React from 'react';

import Logo from '../images/logo.png'
import LogoBlack from "../images/logo-black.png"


export default function({ height='100px', width='auto', className="", black=true }) {
  const styles = { height, width };
  return (
    <img src={black ? `${LogoBlack}` : `${Logo}`} alt="D&W Logo" style={styles} className={className} />
  )
}